import React, {
  useEffect, useState, useRef
} from 'react';
import api from '@modules/api';
import {
  Table,
  Input,
  Space,
  Button,
  Modal,
  Switch,
  Row,
  Col,
  Select,
  InputNumber
} from 'antd';
import {
  translateTypeUser,
  numberFormat,
  parseCurrencyString
} from '@modules/common';
import {SearchOutlined} from '@ant-design/icons';

const getColumnSearchProps = (dataIndex, label, searchInput) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    close
  }) => (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div style={{padding: 8}} onKeyDown={(e) => e.stopPropagation()}>
      <Input
        ref={searchInput}
        placeholder={`Поиск по ${label}`}
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => confirm()}
        style={{
          marginBottom: 8,
          display: 'block'
        }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => confirm()}
          icon={<SearchOutlined />}
          size="small"
          style={{width: 90}}
        >
          Поиск
        </Button>
        <Button
          onClick={() => {
            if (clearFilters) {
              clearFilters();
              confirm();
            }
          }}
          size="small"
          style={{width: 90}}
        >
          Сбросить
        </Button>
        <Button
          type="link"
          size="small"
          onClick={() => {
            confirm({closeDropdown: false});
          }}
        >
          Фильтровать
        </Button>
        <Button type="link" size="small" onClick={() => close()}>
          Закрыть
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered) => (
    <SearchOutlined
      style={{color: filtered ? '#1677ff' : undefined}}
    />
  ),
  onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  filterDropdownProps: {
    onOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    }
  }
});

function Users({toggleLoading}) {
  const [
    users,
    setUsers
  ] = useState([]);
  const [
    user,
    setUser
  ] = useState(null);
  const [
    sideFirms,
    setSideFirms
  ] = useState([]);
  const [
    expenses,
    setExpenses
  ] = useState([]);
  const [
    isOpenModal,
    setIsOpenModal
  ] = useState(false);
  const [
    confirmLoading,
    setConfirmLoading
  ] = useState(false);
  const searchInput = useRef(null);

  useEffect(() => {
    (async () => {
      const response = await Promise.all([
        api('user/list'),
        api('side-firms'),
        api('expense')
      ]);
      if (response[0].status === 200) {
        setUsers(response[0].data);
      }
      if (response[1].data.status) {
        setSideFirms(response[1].data.data);
      }
      if (response[2].data.status) {
        setExpenses(response[2].data.data);
      }
      toggleLoading();
    })();
  }, []);

  const toggleModal = () => setIsOpenModal((prev) => !prev);

  const toggleConfirmLoading = () => setConfirmLoading((prev) => !prev);

  const columns = [
    {
      title: 'ФИО',
      dataIndex: 'fullName',
      key: 'fullName',
      ...getColumnSearchProps('fullName', 'ФИО', searchInput)
    },
    {
      title: 'Доступ к проекту',
      dataIndex: 'access',
      key: 'access',
      render: (value) => (value?.status ? 'Да' : 'Нет'),
      onCell: (record) => ({
        onClick: () => {
          setUser({
            ...record,
            ...(record.access ? {access: record.access} : {access: {status: false}})
          });
          toggleModal();
        }
      })
    },
    {
      title: 'Роль',
      dataIndex: 'type',
      key: 'type',
      render: (value) => translateTypeUser(value)
    },
    {
      title: 'Почта',
      dataIndex: 'email',
      key: 'email',
      ...getColumnSearchProps('email', 'почте', searchInput)
    }
  ];

  const onChangeSwitch = (checked) => {
    setUser({
      ...user,
      access: {status: checked}
    });
  };

  const onChangeUserType = (type) => {
    setUser({
      ...user,
      type
    });
  };

  const onChangeSideFirm = (id) => {
    setUser({
      ...user,
      sideFirmsIds: id
    });
  };

  const handleOk = async () => {
    toggleConfirmLoading();
    await api('user/update', {
      method: 'POST',
      body: user
    });
    toggleConfirmLoading();
    setUsers(users.map((item) => (item.id === user.id ? user : item)));
    toggleModal();
  };

  const changeExpenses = (value) => {
    setUser({
      ...user,
      ignoreExpenses: value.length ? value : null
    });
  };

  const setIgnoreSum = (value) => {
    setUser({
      ...user,
      ignoreSum: value
    });
  };

  return (
    <>
      <Table
        dataSource={users}
        columns={columns}
        // onRow={onRow}
        rowClassName="cursor_pointer"
      />
      <Modal
        width={700}
        open={isOpenModal}
        title={`Редактирование пользователя ${user?.fullName}`}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        // onCancel={toggleModal}
        cancelButtonProps={{disabled: confirmLoading, onClick: toggleModal}}
        closable={false}
      >
        <Space direction="vertical" style={{width: '100%'}}>
          <Row>
            <Col span={6}>Доступ к проекту</Col>
            <Col span={17} offset={1}>
              <Switch checked={user?.access?.status} onChange={onChangeSwitch} />
            </Col>
          </Row>
          <Row>
            <Col span={6}>Роль</Col>
            <Col span={17} offset={1}>
              <Select
                style={{width: '100%'}}
                value={user?.type}
                onChange={onChangeUserType}
                options={[
                  {
                    value: 'user',
                    label: 'Пользователь'
                  },
                  {
                    value: 'admin',
                    label: 'Администратор'
                  },
                  {
                    value: 'auditor',
                    label: 'Ревизор'
                  }
                ]}
              />
            </Col>
          </Row>
          <Row>
            <Col span={6}>Сторонняя фирма</Col>
            <Col span={17} offset={1}>
              <Select
                style={{width: '100%'}}
                value={user?.sideFirmsIds}
                placeholder="Выберете организацию"
                mode="multiple"
                onChange={onChangeSideFirm}
                options={sideFirms.map((value) => ({
                  value: value.id,
                  label: value.name
                }))}
              />
            </Col>
          </Row>
          <Row>
            <Col span={6}>Игнорирование статей</Col>
            <Col span={17} offset={1}>
              <Select
                style={{width: '100%'}}
                value={user?.ignoreExpenses}
                showSearch={false}
                mode="multiple"
                placeholder="Выберете статьи расходов"
                onChange={changeExpenses}
                options={expenses.map((value) => ({
                  value: value.id,
                  label: `${value.number} ${value.name}`
                }))}
              />
            </Col>
          </Row>
          <Row>
            <Col span={6}>Игнорирование суммы</Col>
            <Col span={17} offset={1}>
              <InputNumber
                style={{width: '100%'}}
                placeholder="Введите сумму"
                value={user?.ignoreSum}
                parser={(v) => parseCurrencyString(v)}
                onChange={setIgnoreSum}
                formatter={(v) => numberFormat(v)}
              />
            </Col>
          </Row>
        </Space>
      </Modal>
    </>
  );
}

export default Users;
